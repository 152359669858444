/** @jsx jsx */
import { jsx } from 'theme-ui'
import Img from "gatsby-image"

const PostCard = ({ data }) => (
  <article 
    className="work-list-card"
    sx={{
      //bg: 'cardBg'
    }}
  >
    {data.frontmatter.featuredImage ? 
      (
          <Img 
            fluid={data.frontmatter.featuredImage.childImageSharp.fluid} 
            objectFit="cover"
            objectPosition="50% 50%"
            alt={data.frontmatter.title + ' - Featured image'}
            className="featured-image"
          />
      ) : ""
    }
    <div class="post-content">
      <h2 className="title">{data.frontmatter.title}</h2>
      <p className="description">{data.frontmatter.description}</p>
      <p 
        className="meta"
        sx={{
          color: 'muted',
        }}
      >
      </p>
    </div>
  </article>
)

export default PostCard