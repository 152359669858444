/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { RiArrowRightSLine } from "react-icons/ri"
import {
  RiFacebookBoxFill,
  RiTwitterFill,
  RiLinkedinBoxFill,
  RiYoutubeFill,
  RiInstagramFill,
  RiRssFill,
  RiGithubFill,
  RiTelegramFill,
  RiPinterestFill,
  RiSnapchatFill,
  RiSkypeFill,
  RiDribbbleFill,
  RiMediumFill,
  RiBehanceFill,
  RiZoomInLine,
  RiAwardLine,
  RiUserAddLine,
} from "react-icons/ri"
import { FaWordpress, FaVk } from "react-icons/fa"
import { MdFiberNew } from "react-icons/md"

import { HiMail } from "react-icons/hi"
import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import WorkListHome from "../components/work-list-home"
import SEO from "../components/seo"
import Icons from "../util/socialmedia.json"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredImage {
          childImageSharp {
            fluid(
              maxWidth: 480
              maxHeight: 380
              quality: 80
              srcSetBreakpoints: [960, 1440]
            ) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
        cta {
          ctaText
          ctaLink
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.fluid
    : ""
  const sIcons = Icons.socialIcons.map(icons => {
    return (
      <div>
        {icons.icon === "facebook" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiFacebookBoxFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "twitter" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiTwitterFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "linkedin" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiLinkedinBoxFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "youtube" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiYoutubeFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "instagram" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiInstagramFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "rss" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiRssFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "github" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiGithubFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "telegram" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiTelegramFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "pinterest" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiPinterestFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "snapchat" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiSnapchatFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "skype" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiSkypeFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "wordpress" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <FaWordpress />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "dribbble" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiDribbbleFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "medium" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiMediumFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "behance" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <RiBehanceFill />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "vk" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <FaVk />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "mail" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <HiMail />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "book" ? (
          <Link to={icons.url} target="_blank" rel="noopener">
            <MdFiberNew />
          </Link>
        ) : (
          ""
        )}
      </div>
    )
  })
  return (
    <Layout>
      <SEO />

      <div className="top-description">
        <div className="home-banner grids col-1 sm-2">
          <div>
            <h1 className="title">{frontmatter.title}</h1>
            <p
              className="tagline"
              sx={{
                color: "muted",
              }}
            >
              {frontmatter.tagline}
            </p>
            <hr />
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <Link
              to={frontmatter.cta.ctaLink}
              className="button"
              sx={{
                variant: "links.button",
              }}
            >
              {frontmatter.cta.ctaText}
              <span class="icon -right">
                <RiArrowRightSLine />
              </span>
            </Link>
            <div className="social-icons" sx={indexStyles.socialIcons}>
              {sIcons}
            </div>
            <hr />
          </div>

          <div>
            {Image ? (
              <Img
                fluid={Image}
                alt={frontmatter.title + " - Featured image"}
                className="featured-image"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <WorkListHome />
      <BlogListHome />
      <section className="home-posts">
        <h2>
          <strong>サービスに関するお問い合わせ</strong>
        </h2>

        <hr className="short-line" size="10" />
        <h3>
          <RiUserAddLine />
          教育コンテンツ
        </h3>
        <h3>
          <RiAwardLine />
          セキュリティ教育
        </h3>
        <h3>
          <RiZoomInLine />
          脆弱性診断
        </h3>
        <div className="grids col-1 sm-2 lg-3"></div>
        <Link
          className="button"
          to="/contact"
          sx={{
            variant: "links.button",
          }}
        >
          お問い合わせ / 見積依頼
          <span class="icon -right">
            <RiArrowRightSLine />
          </span>
        </Link>
      </section>
    </Layout>
  )
}

export default HomePage

const indexStyles = {
  socialIcons: {
    a: {
      color: "socialIcons",
      ":hover": {
        color: "socialIconsHover",
      },
    },
  },
}
